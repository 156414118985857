import {createSlice} from "@reduxjs/toolkit";

const initialState = null;

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setBilling: (state) => (!!state ? state : initialState),
    unsetBilling: () => null,
    setName: (state, action) => ({...state, name: action.payload}),
    setEmail: (state, action) => ({...state, email: action.payload}),
    setPhone: (state, action) => ({...state, phone: action.payload}),
    setCompany: (state, action) => ({...state, company: action.payload}),
    setStreet: (state, action) => ({...state, street: action.payload}),
    setZip: (state, action) => ({...state, zip: action.payload}),
    setCity: (state, action) => ({...state, city: action.payload})
  }
});

export const {
  setBilling,
  unsetBilling,
  setName,
  setEmail,
  setPhone,
  setCompany,
  setStreet,
  setZip,
  setCity
} = billingSlice.actions;

export default billingSlice.reducer;
